:root {
    --landing-padding: 200px;
    --section-margin: 100px;
    --landing-color: var(--main-color);
    --text-line-width: 2px;
}

::selection {
    color: white;
    background: var(--landing-color);;
  }

.landing__padding {
    padding-left: var(--landing-padding);
    padding-right: var(--landing-padding);
}

.landing__header {
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    filter: drop-shadow(0 4px 5px rgba(0, 0, 0, 0.1));
}

.nav-show {
    z-index: 10;
    display: flex !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    animation: nav-slide 0.25s ease;
}

.nav-closing {
    animation: nav-remove 0.25s ease;
}

@keyframes nav-slide {
    from {
        transform: translateX(-25%);
        opacity: 0;
    }
}


@keyframes nav-remove {
    to {
        transform: translateX(-25%);
        opacity: 0;
    }
}

.header-shadow, .landing__header {
    height: 80px;
}

.header-fixed {
    position: fixed;
    top: 0;
    animation: header-slide 0.5s ease;
    width: 100%;
}

.landing__burger {
    z-index: 100;
    width: 24px;
    height: 24px;
    background-image: url("../images/icons/burger.svg");
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    display: none;
}

@keyframes header-slide {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0%);
    }
}

.landing__title {
    font-size: 24px;
    color: var(--main-color);
    font-weight: 500;
    z-index: 100;
}

.landing__ul {
    display: flex;
    gap: 25px;
    list-style: none;
}

.landing__li {
    transition: border 0.25s ease;
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0)
}

.landing__link {
    text-transform: uppercase;
    text-decoration: none;
    color: black;
    opacity: 0.75;
    font-weight: 600;
    font-size: 14px;
    padding: 10px;
}

.landing__link:hover {
    opacity: 1;
}

.landing__li:hover {
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.landing-btn {
    padding-left: 40px;
    padding-right: 40px;
    background-color: #dfdfdf;
    border: 2px solid rgb(0, 0, 0, 0.1);
    color: rgb(0, 0, 0, 0.5);
    font-weight: 900;
}

.landing-btn:hover {
    background-color: #bebebe;
}

.landing-extended {
    width: 300px;
}

.landing__section {
    margin-top: var(--section-margin);
    margin-bottom: var(--section-margin);
    display: flex;
    justify-content: space-between;
}

.section-center {
    align-items: center;
    justify-content: center;
}

.section-center .section__info {
    align-items: center;
}

.section__info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 40%;
}

.section-center {
    align-items: center;
}

.landing-showed .section__info {
    animation: section-slide 1s ease
}

.landing-showed-2 .section__info {
    animation: section-slide-right 1s ease
}

.landing-showed .section__items {
    animation: items-appear 1s ease
}

.landing-showed .section__images {
    animation: images-slide 1s ease;
}

.landing-showed .landing__utility {
    animation: items-appear 1s ease;
}

@keyframes section-slide {
    0% {
        transform: translateX(-5%);
    }

    100% {
        transform: translateX(0%);
    }
}

@keyframes section-slide-right {
    0% {
        transform: translateX(5%);
    }

    100% {
        transform: translateX(0%);
    }
}

@keyframes items-appear {
    0% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes images-slide {
    from {
        opacity: 0;
        transform: translateX(10%);
    }
}

.info__title {
    font-size: 32px;
    position: relative;
    text-transform: uppercase;
    font-weight: bold; 
}

.landing-underline {
    padding: 0;
}

.text-center {
    text-align: center;
}

.landing-underline::after {
    width: 0 !important;
}

.info-right .info__title {
    text-align: right;
}

.info-right .info__title::after {
    left: auto;
    right: 0;
}

.info-right .info__text {
    text-align: right;
}

.landing__utilities {
    background-color: #F6F6F6;
    display: flex;
    gap: 25px;
    align-items: center;
    justify-content: center;
    padding: 40px;
}

.landing__utility {
    background-color: inherit;
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 150px;
    height: 80px;
    padding: 5px;
    color: black;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    transition: filter 0.25s ease;
    font-weight: 600;
}

.landing__utility:hover {
    filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.2));
}

.info__list {
    border-top: 1px solid var(--landing-color);
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
}

.landing__item {
    position: relative;
    width: 50%;
    font-size: 12px;
    font-weight: 500;
    line-height: 40px;
    padding-left: 10px;
}

.landing__item::after {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    background-color: var(--landing-color);
    border-radius: 50%;
    left: 0;
    top: 20px;
    transform: translateY(-50%);
}

.items-row {
    gap: 5px !important;
}

.items-row .landing__item {
    font-size: 16px;
    padding-left: 20px;
    width: 200px !important;
}

.landing__message {
    background-color: #414756;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.message-wrapper {
    position: relative;
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding: 40px 20px;

    --decoration-offset: -10px;
}

.message-wrapper .btn {
    margin-top: 10px;
}

.landing-showed .message-wrapper {
    animation: message-slide 0.5s ease;
}

@keyframes message-slide {
    0% {
        transform: translateY(-25%);
    }

    100% {
        transform: translateY(0%);
    }
}

.message-title {
    font-size: 26px;
    font-weight: 600;
}

.message-text {
    font-size: 16px;
    font-weight: 300;
}

.landing__row {
    flex-direction: column;
}

.section__line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--landing-color);
}

.landing__column {
    flex-direction: column;
    gap: 20px;
}

.section__items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
}

.section__items .landing__item {
    width: auto;
}

.landing__social {
    color: var(--landing-color);
    text-decoration: underline;
    cursor: pointer;
}

.landing__footer {
    background-color: #303030;
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.75));
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.footer__side {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    height: 100%;
}

.footer__text {
    color: white;
    font-size: 14px;
    text-align: right;
}

.footer__text a {
    opacity: 0.75;
    color: white;
    font-weight: 500;
    text-decoration: none;
}

.footer__text a:hover {
    opacity: 1;
}

@media only screen and (max-width: 1600px) {
    .landing__section {
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }

    .section__items {
        flex-wrap: wrap;
    }

    .section__info {
        width: 100%;
    }

    .section__line {
        flex-direction: column;
        gap: 40px;
    }

    .section-images {
        flex-direction: column;
    }

    .landing__title {
        font-size: 24px;
    }

    .landing__link {
        font-size: 12px;
    }

    .message-title {
        font-size: 20px;
    }

    .message-text {
        font-size: 14px;
    }
}

@media only screen and (max-width: 1000px) {
    .section__info {
        align-items: center;
    }

    :root {
        --landing-padding: 50px;
    }

    img {
        width: 100%;
    }

    .landing-extended {
        width: 200px;
    }

    .landing__utilities {
        flex-direction: column;
    }

    .landing__title {
        font-size: 16px;
    }

    .landing__link {
        font-size: 8px;
    }

    .info__list {
        flex-direction: column;
    }
    
    .info__list .landing__item {
        width: 100%;
    }

    .message-wrapper {
        width: calc(100vw - var(--landing-padding) * 2);
    }

    .info__title {
        text-align: center !important;
    }

    .info__text {
        text-align: center !important;
    }
}

@media only screen and (max-width: 600px) {
    .landing__header-nav {
        display: none;
        align-items: center;
        justify-content: center;
    }

    .landing__burger {
        display: block;
    }

    .items-row .landing__item {
        font-size: 12px;
    }

    .landing__ul {
        flex-direction: column;
    }

    .landing__li {
        text-align: center;
    }

    .landing__link {
        font-size: 16px;
    }

    .landing__header {
        position: fixed;
        top: 0;
        width: 100%;
    }

    #header-shadow {
        height: 80px;
    }

    .header-fixed {
        animation: none;
    }
}